import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import top from './assets/top.jpg';
import man from './assets/man.png';
import women from './assets/women.png';
import waze from './assets/waze.png';

function App() {

  const [canActive, setCanActive] = useState(false)
  const [password, setPassword] = useState()

  useEffect(() => {
    if (localStorage.getItem('token') === 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY3RpdmUiOiJ0cnVlIn0.7GF1T3uuXbdt5ImTxxCOlbA05Km-0DrbjdMV1y8WlGc') {
      setCanActive(true)
      openGate();
    }

  }, []);

  useEffect(() => {
    if (code === password) {
      active();
      setCanActive(true)
    }
  })

  const openGate = () => {
    fetch('https://maker.ifttt.com/trigger/openGate/json/with/key/b4uu0jv2Py4a-j7B1S3m_5', {
      mode: 'no-cors',
      headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }).then(() => {
      alert('השער נפתח')
    }).catch(error =>
      alert('השער לא נפתח')
      );
  }

  const [code, setCode] = useState("");
  const inputs = useRef([]);

  const handleChange = (event, index) => {
    const { value } = event.target;
    setCode((prevCode) => {
      let newCode = prevCode.slice(0, index) + value + prevCode.slice(index + 1);

      if (index < inputs.current.length - 1 && value) {
        inputs.current[index + 1].focus();
      }
      const date = new Date();
      const hour = String(date.getHours()).padStart(2, '0');
      const day = date.toLocaleString('en-US', { day: '2-digit' });
      setPassword(day.toString() + "" + hour.toString())
      if (code === password) {
        setCanActive(true)
      }
      return newCode;
    });
  };

  const handleReset = () => {
    setCode("");
    inputs.current[0].focus();
  };

  const active = () => {
    localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY3RpdmUiOiJ0cnVlIn0.7GF1T3uuXbdt5ImTxxCOlbA05Km-0DrbjdMV1y8WlGc')
    setCanActive(true);

  }

  return (
    <div className="App">
      {canActive ?
        <div className="phone">
          <img className="banner" src={top} alt="top" onClick={openGate} />
          <button className="open" onClick={openGate}>
            <div className="name open">פתיחת שער</div>
          </button>
          <a className="btn" href="tel:0547633596">
            <img src={man} className="icon" />
            <div className="name">שיחה לניר</div>
          </a>
          <a className="btn" href="tel:0527913201">
            <img src={women} className="icon" />
            <div className="name">שיחה לשיראל</div>
          </a>
          <a className="btn" href="https://www.waze.com/ul">
            <img src={waze} className="icon" />
            <div className="name">אפליקצית Waze</div>
          </a>
          <a className="btn">
            <div className="name">סיסמא לשער 01368 </div>
          </a>
          <div className="about">Made by - Nir Razo</div>
        </div>
        : <div className='lock'>
          <form dir='ltr'>
            {[0, 1, 2, 3].map((i) => (
              <input
                key={i}
                type="text"
                maxLength={1}
                value={code[i] || ""}
                onChange={(event) => handleChange(event, i)}
                ref={(el) => (inputs.current[i] = el)}
              />
            ))}
          </form>
          <button onClick={handleReset} className="reset">איפוס</button>
          {(code === { password }) && (
            <div>
              <button onClick={active()}>כניסה</button>
            </div>
          )}
        </div>}
    </div>
  );
}

export default App;
